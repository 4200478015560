const listeSaisons = [
    {
        id : 1,
        titre : "Beyblade X",
        saison : "Saison 1",
        url : "saison1",
        img : "https://i.ibb.co/mbC48fn/beyximg.webp",
        synopsis : "L'histoire est centrée sur Kazami Bird, un blader amateur qui rêve un jour de devenir professionnel de Beyblade. Son objectif est simple : atteindre le sommet de la X Tower, où se réunissent les meilleurs joueurs de Beyblade.",
        vostfr : true,
        vostfrlink : "/x/saison1/vostfr",
        vf : true,
        vflink : "/x/saison1/vf",
    },
    {
        id : 2,
        titre : "Beyblade X",
        saison : "Scans",
        url : "x",
        img : "https://i.ibb.co/fNk71Hp/Beyblade-X-p1.webp",
        synopsis : "L'histoire du manga est centrée sur Kasami Bird qui souhaite devenir un joueur professionnel de Beyblade. Il souhaite se rendre à la X Tower, où les joueurs professionnels de Beyblade se rassemblent. Le manga introduit de nouveaux systèmes et capacités pour jouer à Beyblade.",
        vf : true,
        vflink : "/x/scans/",
        scan : true
    }
]

export default listeSaisons